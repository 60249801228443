/* File: font.css */
@font-face {
    font-family: 'CeraProBold';
    src: local('CeraProBold'),
        url('../public/fonts/cera/CeraPro-Bold.woff') format('woff'),
        url('../public/fonts/cera/CeraPro-Bold.woff2') format('woff2');
}


@font-face {
    font-family: 'CeraProMedium';
    src: local('CeraProMedium'),
        url('../public/fonts/cera/CeraPro-Medium.woff') format('woff'),
        url('../public/fonts/cera/CeraPro-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'CeraProBlack';
    src: local('CeraProBlack'),
        url('../public/fonts/cera/CeraPro-Black.woff') format('woff'),
        url('../public/fonts/cera/CeraPro-Black.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerBlack';
    src: local('MullerBlack'),
        url('../public/fonts/muller/MullerBlack.woff') format('woff'),
        url('../public/fonts/muller/MullerBlack.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerLight';
    src: local('MullerLight'),
        url('../public/fonts/muller/MullerLight.woff') format('woff'),
        url('../public/fonts/muller/MullerLight.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerBlackItalic';
    src: local('MullerBlackItalic'),
        url('../public/fonts/muller/MullerBlackItalic.woff') format('woff'),
        url('../public/fonts/muller/MullerBlackItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerBold';
    src: local('MullerBold'),
        url('../public/fonts/muller/MullerBold.woff') format('woff'),
        url('../public/fonts/muller/MullerBold.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerRegular';
    src: local('MullerRegular'),
        url('../public/fonts/muller/MullerRegular.woff') format('woff'),
        url('../public/fonts/muller/MullerRegular.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerBoldItalic';
    src: local('MullerBoldItalic'),
        url('../public/fonts/muller/MullerBoldItalic.woff') format('woff'),
        url('../public/fonts/muller/MullerBoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'MullerMediumItalic';
    src: local('MullerMediumItalic'),
        url('../public/fonts/muller/MullerMediumItalic.woff') format('woff'),
        url('../public/fonts/muller/MullerMediumItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'MazzardHRegular';
    src: local('MazzardHRegular'),
        url('../public/fonts/mazzardh/MazzardH-Regular.woff') format('woff'),
        url('../public/fonts/mazzardh/MazzardH-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'MazzardHRegularSemiBold';
    src: local('MazzardHRegularSemiBold'),
        url('../public/fonts/mazzardh/MazzardH-SemiBold.woff') format('woff'),
        url('../public/fonts/mazzardh/MazzardH-SemiBold.woff2') format('woff2');
}


.luminson__nav {
    background-color: #ffffff;
    padding: 1rem 0;
    box-shadow: 0 -1px 8px 0 rgb(32 33 36 / 28%);
}
.luminson__nav button span{
    filter: invert(21%) sepia(1%) saturate(4332%) hue-rotate(10deg) brightness(95%) contrast(91%);;
    font-weight: 500;
}

.luminson__nav .mobileToggle{
    border: solid 2px #97cb9e;
}

.luminson__nav .navbar-nav {
    background-color: #acc245;
}

.luminson__nav .navbar-toggler{
    display: none;
}

.luminson__nav_links a {
    font-family: MazzardHRegularSemiBold;
    margin: 0 0.8rem;
}



.luminson__nav_links a:hover {
    color: #97dbd2 !important
}

.luminson__phone_number {
    color: #000000;
    padding-right: 2rem !important;
    position: relative;
    overflow: hidden;
}

.luminson__phone_number:hover,
.luminson__chat_online:hover {
    color: #acc245;
}

.luminson__phone_number::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
    background-color: #000000;
    display: none;
}

.noDivider .luminson__phone_number::before {
    display: none !important;
}

.luminson__tag_text{
    color: rgba(0,0,0,.5);
    padding-right: 0rem !important;
    position: relative;
    overflow: hidden;
}

.luminson__chat_online {
    color: #000000;
    padding-left: 2rem !important;
    display: none;
}

.luminson__links button {
    padding: 0.7rem 1.1rem;
    font-size: 0.8rem;
    margin-left: 2rem;
}

.luminson__links .sidebarToggle {
    font-size: 1.3rem;
    transform: scale(1.3);
    margin-top: -10px;
}

.luminson__download_icon{
    margin-left: 2rem;
    font-size: 1.5rem;
    display: none;
}


/* Sidebar styles */
.sidebar {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -260px;
    background-color: #ffffff; /* Sidebar background color */
    transition: left 0.3s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.sidebar.active {
    right: 0;
}

.sidebarHeader {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.sidebarToggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #97cb9e; 
    background-color: #ffffff;
    border-radius: 1rem;
}



.sidebarMenu {
    list-style: none;
    padding: 0 10px;
}

.sidebarMenu li {
    margin-bottom: 10px;
}

.sidebarMenu a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
    font-family: 'MazzardHRegular';
    font-size: 1.1rem;
}

.sidebarMenu a:hover {
    background-color: #f0f0f0;
}


.luminson__phone_number{
    color: #585546;
    font-size: 1.1rem;
}


.luminson__nav_container{
    margin: 0 1.5rem
}

@media(max-width: 991px) {
    .luminson__logo {
        width: 150px;
    }

    .scrolled_logo{
        width: 255px;
    }

    .luminson__links {
       display: none !important;
    }

    .luminson__nav_links a {
        margin: 0.5rem 0rem;
    }

    .luminson__nav_links a:last-child{
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
    }

    .luminson__nav .luminson__nav_container{ 
margin: 0;    }
}

@media(max-width: 767px) {
    .luminson__nav{
        padding: 1rem 1rem;
    }
}
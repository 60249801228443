.luminson__wedifferent_section{
    padding: 4.6rem 0;
} 

.luminson_sectionheading{
    margin-bottom: 3rem;
}

@media(max-width: 991px){
    .luminson_sectionheading{
        margin-bottom: 2rem;
    }    
  }
.luminson__blog_section{
    padding: 4.6rem 0;
}

.luminson__post_card_image{
    height: 500px;
    position: relative;
    border-radius:1rem;
    margin-bottom: 30px;
}

.luminson__post_card_image img{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:1rem;
}

.luminson__post_card_title{
}

.luminson__post_card_title h2{
    font-family: 'MullerBlack';
    margin-top: 1.3rem;
    font-size: 44px;
}

.luminson__post_card_meta{
    margin: 0.5rem 0 1.5rem
}

.luminson__post_card_meta span{
    font-size: 14px;
    color: #2c2c2c;
}

.luminson__post_card_button{
    text-align: center;
    margin-top: 30px;
    background-color: #007bff;
    background-image: url(../sections/images/process-bg.webp);
    background-size: cover;
    background-position: 0px 1070px;
    padding: 25px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
}

.luminson__post_card_button::after{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #33333373;
}

.luminson__post_card_button span:first-child{
    padding-left: 20px;
}

.luminson__post_card_button span h1{
    color: #ffffff;
    text-align: left;
    font-size: 26px;
    position: relative;
    z-index: 9;
}

.luminson__post_card_button span h2{
    font-size: 20px;
    font-family: MazzardHRegular !important;
    color: #ffffff;
    margin-bottom: 0;
    position: relative;
    z-index: 9;
}

.luminson__post_card_button span p{
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 5px;
    font-size: 12px;
    position: relative;
    z-index: 9;
}

.luminson__post_card_button button{
    float: right;
    position: relative;
    z-index: 9;
}

.luminson__post_card_desc img{
    width: 100%;
}

.luminson__post_card_desc p, .luminson__post_card_desc ul, .luminson__post_card_desc ol{
    font-family: MazzardHRegular !important;

}


.luminson__post_card_desc p strong{
    font-family: MazzardHRegular !important;
    font-weight: 400;
}

.luminson__post_card_desc h1{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 32px;
  }

  .luminson__post_card_desc h2{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 28px;
  }

  .luminson__post_card_desc h3{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 24px;
  }

  .luminson__post_card_desc h4{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 20px;
  }
  .luminson__post_card_desc h5{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 16px;
  }
  .luminson__post_card_desc h6{
    font-weight: bold;
    font-family: 'MullerBold';
    font-size: 12px;
  }



.luminson__post_card_desc a{
    text-decoration: underline;
}

.luminson__post_card_desc a:hover{
    color: #4db48d !important;
}

.luminson__sidebar_recent {
  }
  
  .luminson__sidebar_recent h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .luminson__sidebar_recent ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .luminson__sidebar_recent li {
    margin-bottom: 14px;
  }
  
  .luminson__sidebar_recent a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
    display: block;
    color: #76bfbb;
    font-size: 19px;
  }
  
  .luminson__sidebar_recent a:hover {
    color: #4db48d;
    text-decoration: underline;
  }
  
  .luminson__sidebar_recent span{
    font-size: 13px;
  }

  .luminson__related_posts{
    margin-top: 45px;
  }

  .luminson__related_posts h3 {
    margin-bottom: 30px;
  }

  .luminson__sidebar_book_form{
    padding: 20px;
    margin-bottom: 40px;
    border-radius: 1rem;
    background-color: #3b3a37;
  }
  .luminson__sidebar_book_form p {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
  }

  .luminson__sidebar_book_form label{
    color: #fff;
  }

 


.luminson__author_block {
    background-color: #f6fffc;
    padding: 20px 0;
    margin-top: 60px;
    display: block;
  }
  
  .luminson__author_image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 30px auto -40px;
    border: 2px solid #85e0bd; /* Customize border color */
  }
  
  .luminson__author_name {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
  }
  
  .luminson__author_summary {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  
  .luminson__author_columnDivider::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #dadada; 
    position: absolute;
    right: 0%;
    top: 0;
    transform: translateX(-50%);
  }
  
  .luminson__author_block .container {
    position: relative;
  }
  
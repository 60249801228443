.container {
    text-align: center;
    padding: 50px;
  }
  
  .title {
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .text {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .link {
    outline: 0;
    font-family: 'MullerBold';
    border-radius: 2rem;
    color: #ffffff;
    text-transform: uppercase;
    padding: 0.7rem 1.1rem;
    background-color: #3b3a36;
    border: 3px #3b3a36 solid;
    color: #ffffff;
    display: inline-block;
    margin-top: 20px;
  }
  
  .link:hover {
    background-color: #3b3a36;
    text-decoration: none;
    color: #ffffff;
  }
  
.luminson__lessexpensive_section{
    padding: 4.6rem 0;
    background-color: #3b3a36;
}

.luminson_sectionheading{
    margin-bottom: 3rem;
}


.luminson_sectionheading h1{
    color: #ffffff;
}


.luminson_sectionheading h5{
    color: #ffffff;
}

.luminson_bottomheading{
    margin-top: 3rem;
}

.luminson_bottomheading h2{
    color: #ffffff;
    margin-bottom: 0;
}

.luminson_bottomheading *{
    margin-bottom: 0;
}

.luminson_bottomheading h1{
    color: #ffffff;
}

@media(max-width: 991px){
    .luminson_sectionheading{
        margin-bottom: 2rem;
    }
    
    .luminson_bottomheading{
        margin-top: 2.5rem;
    }
  }

  @media(max-width: 767px){
    .luminson_bottomheading{
        margin-top: 2rem !important;
    }
  }
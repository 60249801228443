.luminson__post_card {
    background-color: #ffffff;
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    border-radius: 1rem;
    height: 100%;
}

.luminson__post_slug{
    color: #2c2c2c;
}

.luminson__post_slug:hover{
    text-decoration: none;
    color:initial;
}

.luminson__post_card_image {
    border-radius: 1rem 1rem 0 0;
    height: 220px;
    background-color: transparent;
    position: relative;
}

.luminson__post_card_image img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.luminson__post_card_title{
    padding: 0 20px;
}

.luminson__post_card_title h4{
    font-family: 'MazzardHRegularSemiBold';
    margin-top: 1.3rem;
    font-size: 18px;
}

.luminson__post_card_meta{
    margin: 0.5rem 0;
    padding: 0 20px;
}

.luminson__post_card_meta span{
    font-size: 14px;
    color: #2c2c2c;
}

.luminson__post_card_desc{
    padding: 0 20px 10px;
    border-radius:0 0 1rem 1rem;
}




.luminson__related_post{

}

.luminson__related_title{
    padding: 0 14px;
}

.luminson__related_title h4{
    font-size: 19px;
    word-break: keep-all;
}

.luminson__related_image{
    height: 150px;
}

.luminson__related_desc{
    font-size: 14px;
    padding: 0 14px 5px;
}
.luminson__featuring_section{
    background-color: #B4B1AD;
}

.luminson__featuring_section *{
    color: #000000;
}

.luminson__left_col{
    padding: 4.6rem 0;
}

.luminson__left_col img{
    width: 100%;
    filter: grayscale(100%) invert(0);
}

.luminson__right_col{
    padding: 4.6rem 0;
}

.luminson__right_col h3{
    color: #000000;
    font-size: 3rem;
    margin-top: 3rem;
    text-align: center;
    font-family: MullerBold;
}

.luminson__right_col .luminson__col{
    text-align: center;
}

.luminson__right_col .luminson__col .luminson__large{
    display: block;
    font-size: 2.8rem;
    font-family: 'MullerBlack';
}

.luminson__right_col .luminson__col .luminson__small{
    display: block;
    font-family: 'MullerBlack';
}

@media(max-width: 991px) {
    .luminson__right_col{
        padding: 2.6rem 0;
    }

    .luminson__right_col h3{
        font-size: 2.5rem !important;
        margin-top: 2.5rem;
    }

    .luminson__right_col .luminson__col .luminson__large{
        font-size: 2.5rem;
    }
    
    .luminson__right_col .luminson__col .luminson__small{
        font-size: 0.9rem;
    }

    .luminson__left{
        display: none !important;
    }
}

@media(max-width: 767px) {
    .luminson__right_col{
        padding: 2.6rem 0;
    }

    .luminson__right_col .luminson__col{
        margin-bottom: 2rem;
    }

    .luminson__right_col  .luminson__no_padding .luminson__col{
        margin-bottom: 0;
    }

    .luminson__right_col h3{
        font-size: 1.6rem !important;
        margin-top: 2rem;
    }

    .luminson__right_col .luminson__col .luminson__large{
        font-size: 1.6rem;
    }
    
    .luminson__right_col .luminson__col .luminson__small{
        font-size: 0.9rem;
    }

    .luminson__left{
        display: none !important;
    }
}
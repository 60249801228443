.luminson__bold_section{
    background-image: linear-gradient(363deg, #8cd0b6, #f7f3a4 85%);
    padding: 35px 0;
}

.luminson__bold_section h1{
    color: #ffffff;
    margin: 0;
    text-align: center;
    text-transform: none;
    font-size: 30px;
}
.textUnderline{
    position: relative;
    display: inline-block !important;
}

.textUnderline::after{
    position: absolute;
    content: '';
    height: 6px;
    width: 100%;
    bottom: 5px;
    left: 0;
    background-color: #8cd0b6;
}

.luminson__hero_section {
    padding: 75px 0;
    background-color: #3b3a37;
    background-image: url(../sections/images/design.png);
    border-image: linear-gradient(363deg, #8cd0b6, #f7f3a4 85%);
    border-width: 6px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-size: cover;
}

.luminson__hero_section .left{
    position: relative;
    display: flex;
    align-items: center;
}

.luminson__hero_section .left svg{
    position: absolute;
    width: 60px;
    bottom: 0;
    height: 100%;
    right: 20px;
    transform: rotate(-40deg);
    margin-bottom: -111px;
}

.luminson__hero_section h1 {
    font-size: 46px;
    color: #ffffff;
    font-family: MullerBlack;
    margin-bottom: 20px;
    padding-right: 100px;
    line-height: 3.8rem;
}

.luminson__hero_section h1 span{
    display: block;
}

.luminson__hero_section h2 {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 20px;
    padding-right: 100px;
    font-family: MullerBold;

}

.luminson__hero_section h2 span {
    font-family: MullerBoldItalic;
}

.luminson__hero_section p {
    color: #ffffff;
    padding-right: 100px;
    text-align: justify;
}

.aboveFormText{
    padding: 0 !important;
    font-size: 13px;
}

.aboveFormText span:first-child{
    font-family: MullerBoldItalic;
}

.aboveFormText span:last-child{
    color: #f7f3a4;        
}

.luminson__hero_section form label {
    text-transform: uppercase;
    color: #ffffff;
}

.luminson__hero_section small {
    color: #a5a5a5;
    float: right;
    margin-top: 10px;
    font-size: 10px;
}



.luminson__hero_section form input {
    padding: 20px 15px
}

.luminson__hero_section form button {
    padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(363deg, #8cd0b6, #f7f3a4 85%);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  outline: none;
}

.luminson__hero_section form button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increase the shadow on hover */
    outline: none;
}

.luminson__hero_section form button:focus{
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important
}
.luminson__book_section {
    background-color: #ffffff;
    padding: 75px 0;
}

.luminson__book_section h1 {
    font-size: 42px;
    font-family: MullerBold;
    margin-bottom: 20px;
    color: #3b3a37;
}

.luminson__book_section h1 span:first-child {
    font-family: MullerBoldItalic;
}

.luminson__book_section h1 span:last-child {
    font-family: MullerLight;
}

.luminson__book_section ul {
    list-style: none;
    padding-left: 0;
    padding-right: 0px;
}

.luminson__book_section ul svg {
    width: 60px;
    flex-shrink: 0;
    margin-top: -15px;
    margin-right: 0.8rem;
    height: 100%;
}

.luminson__book_section ul li {
    font-family: MullerRegular;
    font-size: 22px;
    margin-bottom: 15px;
    color: #3b3a37;
    /* display: flex;
    align-items: flex-start; */
}

.luminson__book_section ul li span{
    color: #d0ce8c;
    display: block;
}


.luminson__book_section .button {
    padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(363deg, #3b3a37, #3b3a37 85%);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  outline: none;
  text-decoration: none;
}

.wrapper {
    padding: 0 100px;
}

.luminson__book{
    width: 285px;
    margin-top: -15px;
}

@media (max-width: 767px) {
    .luminson__bold_section{
        padding: 15px 0 !important;
    }
    .luminson__bold_section h1{
        font-size: 23px;
        text-align: center;
    }

    .wrapper {
        padding: 0 0;
    }

    .luminson__hero_section {
        padding: 35px 0;
    }

    .luminson__hero_section h1 {
        text-align: center !important;
        font-size: 30px;
        padding-right: 0;
    }

    .luminson__hero_section h2 {
        font-size: 26px;
        text-align: center !important;
        padding: 0;
    }

    .luminson__hero_section p{
        font-size: 12px;
        text-align: center;
        padding: 0;
    }

    .luminson__hero_section form label {
        font-size: 12px;
    }

    .luminson__hero_section .left svg{
        display: none;
    }

    .luminson__book_section{
        padding: 35px 0;
    }

    .luminson__book_section h1 {
        font-size: 30px;
        text-align: center;
    }

    .luminson__book_section ul {
        padding: 0;
        padding: 0 35px;
    }

    .luminson__book_section ul li {
        font-size: 18px;
    }

    .luminson__book_section ul svg {
        width: 30px;
        margin-top: -3px;
    }

    .luminson__book_wrapper{
        text-align: center;
        margin-bottom: 20px;
    }
  }
  
  /* Styles for tablet devices (768px to 991px) */
  @media (min-width: 768px) and (max-width: 991px) {

    .luminson__bold_section{
        padding: 25px 0 !important;
    }

    .wrapper {
        padding: 0 20px;
    }

    .luminson__hero_section h1 {
        font-size: 28px;
        padding-right: 60px;
    }

    .luminson__hero_section h2 {
        font-size: 28px;
        padding-right: 60px;
    }

    .luminson__hero_section p {
        font-size: 13px;
        padding-right: 60px;
    }

    .TruthAboutSolar_luminson__hero_section__uSFM2 p{
        font-size: 14px;
    }

    .luminson__hero_section form label {
        font-size: 12px;
    }

    .luminson__book_section h1 {
        font-size: 28px;
    }

    .luminson__book_section ul svg {
        width: 45px;
        margin-top: -9px;
    }

    .luminson__book_section ul li {
        font-size: 18px;
    }

    .luminson__book{
        width: 220px;
        margin-top: -10px !important;
    }

    .wrapper {
        padding: 0;
    }

    .luminson__bold_section h1{
        font-size: 30px;
    }
  }
  
.luminson___primary_btn{
    background-color: #398e83;
    border: 3px #398e83 solid;
    outline: 0;
    padding: 0.99rem 1.4em;
    font-family: 'MullerBold';
    border-radius: 2rem;
    color: #ffffff;
    font-size: 0.80rem;
    text-transform: uppercase;
}

.luminson___primary_btn:hover, .luminson___primary_btn:focus, .luminson___primary_btn:active{
    background-color: #398e83;
    border: 3px transparent solid;
    color: #ffffff;
    outline: 0;
    box-shadow: 0 0 0 transparent;
}

.luminson___primary_btn.yellow{
    background-color: #efba12;
    border: 3px #efba12 solid;
    color: #ffffff;
}

.luminson___primary_btn.yellow:hover, .luminson___primary_btn.yellow:active{
    background-color: #f3c018 !important;
    border: 3px #f3c018 solid !important;
    color: #ffffff !important;
    outline: 0 !important;
}

.luminson___primary_btn.yellow:active{
    -webkit-box-shadow: inset 1px 1px 32px #33333347 !important;
   -moz-box-shadow:    inset 1px 1px 32px #33333347 !important;
   box-shadow:         inset 1px 1px 32px #33333347 !important;
}



.luminson___primary_btn.red{
    /* background-color: #eb5d54; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);

    /* border: 3px #eb5d54 solid; */
    border: 0;
    color: #3b3a36;
}

.luminson___primary_btn.red:hover, .luminson___primary_btn.red:active{
    /* background-color: #e24237 !important;
    border: 3px #e24237 solid !important; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    border:0 !important;
    color: #3b3a36 !important;
    outline: 0 !important;
}

.luminson___primary_btn.red:active{
    -webkit-box-shadow: inset 1px 1px 32px #33333347 !important;
   -moz-box-shadow:    inset 1px 1px 32px #33333347 !important;
   box-shadow:         inset 1px 1px 32px #33333347 !important;
}



.luminson___primary_btn.beige{
    /* background-color: #EBE8E3; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    /* border: 3px #EBE8E3 solid; */
    border: 0;
    color: #3b3a36;
}

.luminson___primary_btn.beige:hover, .luminson___primary_btn.beige:active{
    /* background-color: #f7f1e6 !important;
    border: 3px #f7f1e6 solid !important;
    color: #3b3a36 !important; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    color: #3b3a36;
    outline: 0 !important;
}

.luminson___primary_btn.beige:active{
    -webkit-box-shadow: inset 1px 1px 32px #33333347 !important;
   -moz-box-shadow:    inset 1px 1px 32px #33333347 !important;
   box-shadow:         inset 1px 1px 32px #33333347 !important;
}




.luminson___primary_btn.green{
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    color: #3b3a36;
    border: 0;
}

.luminson___primary_btn.green:hover, .luminson___primary_btn.green:active{
    background-color: #81e6c1 !important;
    border: 0 !important;
    color: #3b3a36 !important;
}

.luminson___primary_btn.green:active{
    -webkit-box-shadow: inset 1px 1px 32px #33333347 !important;
   -moz-box-shadow:    inset 1px 1px 32px #33333347 !important;
   box-shadow:         inset 1px 1px 32px #33333347 !important;
}

.luminson___primary_btn.black{
    background-color: #3b3a36;
    border: 3px #3b3a36 solid;
    color: #ffffff;
}

.luminson___primary_btn.black:hover, .luminson___primary_btn.black:active{
    background-color: #31302b !important;
    border: 3px #31302b solid !important;
    color: #ffffff !important;
}

.luminson___primary_btn.black:active{
    -webkit-box-shadow: inset 1px 1px 32px #111111dc !important;
   -moz-box-shadow:    inset 1px 1px 32px #111111dc !important;
   box-shadow:         inset 1px 1px 32px #111111dc !important;
}

/* .luminson___primary_btn .luminson__btn_icon{
    display: inline;
    padding: 0 0rem 0.5rem 0.2rem;
    font-size: 1.3rem;
    margin-top: -1rem;
} */

.luminson___primary_btn:has(svg){
    position: relative;
    padding-right: 3rem;
    text-align: left;
}

.luminson___primary_btn:has(svg) .luminson__btn_icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    right: 0;
    margin-right: 1.4rem;
    font-weight: bolder;
    margin-top: -0.2rem;
}



@media(max-width: 767px) {
    .luminson___primary_btn{
        font-size: 0.7rem;
        border: 5px #73bfb8 solid;
    }
}
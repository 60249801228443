.luminson__footer{
    padding: 4.6rem 0 2rem;
    background-color: #1B1B19;
}

.luminson__footer_col{

}

.luminson__footer_col:nth-child(2){
    padding-left: 2rem;
}

.luminson__footer_col:nth-child(3){
    padding-left: 3rem;
}

.luminson__footer_col .luminson__footer_links{
    padding: 0;
    margin: 0;
    list-style: none;
    padding-right: 0.5rem;
}

.luminson__footer_col .luminson__footer_links li{
    margin-bottom: 1.6rem;
}

.luminson__footer_col .luminson__footer_links li a{
    color: #b6b6b6;
    text-decoration: none;
    font-size: 1rem;
    font-family: MazzardHRegular;
}

.luminson__footer_col .luminson__footer_links li a:hover{
    color: #73bfb8;
}

.luminson__footer_col .luminson__footer_title{
    font-family: CeraProBold;
    color: #dbdbdb;
    text-transform: uppercase;
    margin-bottom: 1.4rem;
}

.luminson__social_links{
    margin-top: 1rem;
    text-align: center;
    width: 100%;
}

.luminson__social_links h5{
    color: #dbdbdb;
}

.luminson__social_links ul{
    list-style: none;
    display: inline-block;
    padding-left: 0;
}

.luminson__social_links ul li{
    display: inline;
    padding-left: 1rem;
}

.luminson__social_links ul li:first-child{
    padding-left: 0;
}

.luminson__social_links ul li a{
    font-size: 1.8rem !important;
    color: #b6b6b6;
}

.luminson__social_links ul li a:hover{
    color: #73bfb8;
}

.luminson__copyright{
    margin-top: 3rem;
    text-align: center;
    width: 100%;
}

.luminson__copyright p{
    font-size: 1rem;
    color: #b6b6b6;
    margin: 0;
}

.luminson__copyright a:hover{
    color: #73bfb8;
}

.luminson__sister_companies{
    display: block;
    text-align: center;
}

.luminson__sister_companies img{
    width: 200px;
    /* filter: invert(1) grayscale(100%); */
}


@media(max-width: 1199px) {
    .luminson__footer_col .luminson__footer_links li a{
        font-size: 0.9rem;
    }
}

@media(max-width: 991px) {
    .luminson__footer_col:nth-child(3){
        padding-left: 2.8rem;
    }

    .luminson__footer_col .luminson__footer_title{
        margin-bottom: 1.4rem;
        font-size: 1rem;
    }

    .luminson__footer_col .luminson__footer_links li{
        padding-bottom: 0.9rem;
    }

    .luminson__footer_col .luminson__footer_links li a{
        font-size: 0.9rem;
    }

    .luminson__social_links ul li a{
        font-size: 1.5rem;
    }

    .luminson__social_links{
        margin-top: 2rem;
    }

    .luminson__social_links h5{
        font-size: 1rem;
    }

    .luminson__copyright{
        margin-top: 2rem;
    }

    .luminson__copyright p{
        font-size: 0.9rem;
    }
    
    .luminson__sister_companies img{
        width: 125px;
    }
}

@media(max-width: 767px) {
    .luminson__footer_col{
        margin-bottom: 2rem;
    }

    .luminson__footer_col:last-child{
        margin-bottom: 0;
    }

    .luminson__footer_col:nth-child(3){
        padding-left: 15px;
    }

    .luminson__footer_col .luminson__footer_title{
        margin-bottom: 1rem;
        font-size: 0.9rem;
        text-align: center;
    }

    .luminson__footer_col .luminson__footer_links li{
        padding-bottom: 0.7rem;
    }

    .luminson__footer_col .luminson__footer_links{
        display: table;
        margin: 0 auto;
        text-align: center;
    }

    .luminson__footer_col .luminson__footer_links li a{
        font-size: 0.9rem;
    }

    .luminson__social_links ul li a{
        font-size: 1.5rem;
    }

    .luminson__social_links{
        margin-top: 2rem;
    }

    .luminson__social_links h5{
        font-size: 1rem;
    }

    .luminson__copyright{
        margin-top: 2rem;
    }

    .luminson__copyright p{
        font-size: 0.9rem;
    }

    .luminson__sister_companies img{
        width: 140px;
    }
}
.luminson__hero_section {
    padding: 4.6rem 0;
    background-color: #ffffff;
}

.luminson__hero_image {
    height: 440px;
    position: relative;
}

.luminson__hero_image img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.luminson__hero_caption {
    text-align: left;
    padding-right: 1rem;
}

.luminson__hero_caption h1 {
    font-size: 3.2rem;
    color: #000000;
}

.luminson__hero_caption h4 {
    font-size: 2rem;
    color: #000000;
}

.luminson__hero_caption h6 {
    font-size: 1.40rem;
    color: #000000;
}

.luminson__hero_caption .infoText {
    margin-top: 2rem;
}

.luminson__hero_caption .infoText h6 {
    margin-bottom: 2rem;
}

@media(max-width: 991px) {
    .luminson__hero_image {
        height: 350px;
    }

    .luminson__hero_caption h1 {
        font-size: 2.3rem;
    }

    .luminson__hero_caption h4 {
        font-size: 1.4rem;
    }

    .luminson__hero_caption h6 {
        font-size: 1.1rem;
    }

    .luminson__hero_caption {
        padding-right: 0.3rem !important;
    }

    .luminson__hero_caption .infoText {
        font-size: 0.9rem !important;
    }


    @media(max-width: 767px) {
        .luminson__hero_caption {
            text-align: center;
        }

        .luminson__hero_caption h1 {
            font-size: 2rem;
        }

        .luminson__hero_caption h4 {
            font-size: 1.2rem;
        }

        .luminson__hero_caption h6 {
            font-size: 1rem;
        }

        .luminson__hero_caption {
            padding-right: 0rem !important;
        }

        .luminson__hero_caption .infoText {
            font-size: 0.9rem !important;
        }

        .luminson__hero_image {
            height: auto;
            margin-top: 2rem;
        }

        .luminson__hero_image img {
            position: relative;
        }

        .luminson__hero_caption .infoText {
            margin-top: 1rem;
        }
    }
}
.luminson__certifiedadvisors_section{
    padding: 4.6rem 0;
    background-color: #ffffff;
}

.luminson__certifiedadvisors_image{
    height: 450px;
    position: relative;
    margin: 0 60px;
}

.luminson__certifiedadvisors_image img{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.luminson__certifiedadvisors_card{
    text-align: center;
}


@media(max-width: 991px) {
    .luminson__certifiedadvisors_section p {
        font-size: 0.9rem;
    }

    .luminson__certifiedadvisors_image {
        height: 400px;
        margin: 0 10px;
    }
}


@media(max-width: 767px) {
    .luminson__leader_caption {
        padding-left: 15px;
    }

    .luminson__leader_caption *{
        text-align: center;
    }

    .luminson__certifiedadvisors_image {
        height: auto;
        margin: 0;
        margin-bottom: 2rem;
    }

    .luminson__certifiedadvisors_image img {
        position: relative;
    }
}
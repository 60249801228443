.luminson__testimonials_section {
    padding: 4.6rem 0;
    background-color: #3B3A36;
    overflow: hidden;
}

.luminson_sectionheading {
    margin-bottom: 3rem;
}

.luminson_sectionheading *{
    color: #ffffff;
}

.luminson_review_slider {
    background-color: #3B3A36;
    position: relative;
}

.luminson_review_slider::before {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #3B3A36;
}

.luminson_review_slider::after {
    content: '';
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #3B3A36;
}

@media(max-width: 991px) {
    .luminson_sectionheading {
        margin-bottom: 3rem;
    }

    .luminson_review_slider {
        padding: 0rem 0;
    }
}

@media(max-width: 767px) {
    .luminson_review_slider {
        padding: 0rem 0 0;
    }

    .luminson_sectionheading {
        margin-bottom: 2rem !important;
    }
}
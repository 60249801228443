.luminson__twocolsection_section {
    padding: 4.6rem 0;
}

.luminson__twocolsection_image {
    height: 400px;
    position: relative;
}

.luminson__twocolsection_image img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.luminson__twocolsection_heading{
    text-align: center;
}   

.luminson__twocolsection_caption {
    padding-left: 3rem;
    text-align: center;
}

.luminson__twocolsection_caption button{
    margin-top: 1rem;
}

.luminson__twocolsection_flip.luminson__twocolsection_caption {
    padding-right: 3rem;
    text-align: center;
}


@media(max-width: 991px) {
    .luminson__twocolsection_caption {
        padding-left: 2rem;
    }

    .luminson__twocolsection_flip.luminson__twocolsection_caption {
        padding-right: 2rem !important;
    }

    .luminson__twocolsection_section p {
        font-size: 0.9rem;
    }

    .luminson__twocolsection_image {
        height: 330px;
        position: relative;
    }
}


@media(max-width: 767px) {
    .luminson__twocolsection_caption {
        padding-left: 15px !important;
    }

    .luminson__twocolsection_flip.luminson__twocolsection_caption {
        padding-right: 15px !important;
    }

    .luminson__twocolsection_caption * {
        text-align: center;
    }

    .luminson__twocolsection_image {
        height: auto;
        margin-bottom: 2rem;
    }

    .luminson__twocolsection_image img {
        position: relative;
    }
}
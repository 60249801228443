.luminson__card_block .luminson__single_card {
    background-color: #3b3a36cc;
    height: 100%;
    padding-top: 3rem;
    position: relative;
}

.luminson__card_block .luminson__single_card h3 {
    color: #eaf7f6;
    font-size: 1.5rem;
    text-align: center;
    font-family: MullerBold;
}

.luminson__card_block .luminson__single_card p {
    color: #eaf7f6;
    text-align: center;
}

.luminson__card_block .luminson__number {
    position: absolute;
    left: 50%;
    top: 0;
    width: 5rem;
    transform: translate(-50%, -50%);
    /* background-color: #84E0BD; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    padding: 1rem 2rem 1rem;
    border-radius: 3rem;
    color: #3b3a36;
    font-size: 2rem;
    font-family: 'CeraProBold';
}

@media(max-width: 991px) {
    .luminson__card_block .luminson__single_card h3 {
        font-size: 1.2rem !important;
    }

    .luminson__card_block .luminson__single_card p {
        font-size: 0.9rem;
    }

    .luminson__card_block .luminson__number {
        font-size: 1rem;
        padding: 1.3rem 1.8rem 1.11rem;
        width: 4rem;
    }
}

@media(max-width: 767px) {
    .luminson__card_block{
        margin-bottom: 4rem;
    }

    .luminson__card_block:last-child{
        margin-bottom: 0;
    }

    .luminson__card_block .luminson__single_card {
        padding-top: 2rem;
    }
}
/* MyCalendlyComponent.module.css */

.calendlyContainer {
    overflow: hidden; /* Hide any content that overflows the container */
  }
  
  .calendlyRow {
    margin-right: -15px; /* Remove right margin to avoid horizontal scroll */
    margin-left: -15px; /* Remove left margin to avoid horizontal scroll */
  }
  
  .calendlyCol {
    padding-right: 15px; /* Add padding to columns to align with the row */
    padding-left: 15px; /* Add padding to columns to align with the row */
  }
  
  .calendlyInlineWidget {
    height: calc(100vh - 100px); /* Set the widget height to full viewport height */
    overflow: auto; /* Allow the widget to scroll vertically if needed */
    scrollbar-width: thin; /* Set the width of the scrollbar */
    scrollbar-color: transparent transparent; /* Set the color of the scrollbar */
  }
  
  .calendlyInlineWidget::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }
  
  .calendlyInlineWidget::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the color of the scrollbar thumb */
  }
  
  .calendlyInlineWidget::-webkit-scrollbar-track {
    background-color: transparent; /* Set the color of the scrollbar track */
  }
  
  @media (max-width: 767px) {
  .calendlyInlineWidget {
    height: calc(100vh - 285px); /* Subtract the height of the mobile browser's address bar (typically 56px) */
  }
}
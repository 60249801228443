.luminson__medialogos_section {
    padding: 2.2rem 0;
    background-color: #ebe7e3;
}

.luminson__moving_logos{
    display: none;
}

@media(max-width: 991px) {
    .luminson__moving_logos{
        display: none;
    }
}



@media(max-width: 767px) {
    .luminson__medialogos_section {
        padding: 1.5rem 0 !important;
    }
    .luminson__static_logos{
        display: none;
    }

    .luminson__moving_logos{
        display: block;
        text-align: center;
    }
}
.image{
    max-width: 150px;
    filter: saturate(500%) contrast(800%) brightness(500%) invert(50%) sepia(9%) hue-rotate(252deg) grayscale(0);
}

.image:hover{
    max-width: 150px;
    filter:unset;
}

@media(max-width: 991px) {
    .image{
        max-width: 100px;
    }
}

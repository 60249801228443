.luminson__forgetsolar_section{
    padding: 1.6rem 0;
    background-color: #ffffff;
}

@media(max-width: 991px) {
    .luminson__forgetsolar_section{
        padding: 0.6rem 0 !important;
    }
}

@media(max-width: 767px) {
    .luminson__forgetsolar_section{
        padding: 0.6rem 0 !important;
    }
}
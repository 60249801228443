.customBtn{
    background-color: #ffb300 !important;
    color: #000000;
    border-color: transparent;
    padding: 12px 0;
    font-size: 18px;
}   

.customBtn:hover{
    background-color: green !important
} 

.form{
    font-family: 'MazzardHRegular';
}

@media (max-width: 767px) {


}
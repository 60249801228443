.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(120 113 10 / 29%); /* Adjust the opacity as desired */
  z-index: 1;
}

.luminson__hero_section{
    height: 800px;
    position: relative;
    border-bottom: #ffe300 25px solid;
}

.luminson__hero_section .luminson__hero_section_background_image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
}

.luminson__hero_section_wrapper{
    text-align: center;

}

.luminson__hero_section_wrapper .h1{
    font-size: 120px;
    font-weight: 700;
    line-height: 10rem;
    font-family: MullerBold;
    color: #ffffffe0;
}

.luminson__hero_section_wrapper .h1 span{
    background-color: #ffe300a1   ;
}

.luminson__hero_section_wrapper .h5{
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    font-family: MullerBold;
    margin: 25px 0;
}

.luminson__hero_section_wrapper .h6{
    font-size: 20px;
    font-weight: 400;
    color: #ffffffef;
}



.luminson__hero_section_container{
    height: 100%;
    position: relative;
    z-index: 2;
}

.luminson__hero_section_row{
    height: 100%;
}





.luminson__howitworks_section{
    padding: 70px 0;
}

.luminson__howitworks_section .sectionHeading{
    font-size: 65px;
    font-family: MullerBold;
    color: #3a810d;
    text-align: center;
    margin-bottom: 40px;
}

.luminson__howitworks_wrapper{
    text-align: center;
}

.circle {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: #ffe300;
    color: #000000;
    font-size: 32px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  
  .circleNumber {
  }

  .circleNumber img{
    width: 200px !important;
}
  
  .luminson__howitworks_wrapper .h4{
    font-weight: bold;
    text-decoration: none;
    font-style: normal;
    color: #0c5a16;
    font-size: 25px;
    padding: 22px 0 12px;
  }

  .luminson__howitworks_wrapper p{
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-size: 15px;
  }

  .extraText{
    font-family: MullerMediumItalic;
    font-style: italic;
    padding-top: 30px;
    text-align: center;
    color: #6e6e6e;
  }

  .luminson__widetext_section{
    padding: 70px 0;
    background-color: #d3e4d4;
    text-align: center;
  }

  .luminson__widetext_section .h4{
    text-align: center;
    font-family: MullerMediumItalic;
    color: #340036;
    font-size: 30px;
    padding: 0 100px;
  }


  .luminson__thankyou_section{
    padding: 70px 0;
  }

  .luminson__thankyou_section img{
    width: 100%;
  }

.luminson__thankyou_section .content{

}

.luminson__thankyou_section .content .h4{
    font-size: 35px;
    font-family: MullerBold;
    color: #3a810d;
    margin-bottom: 40px;
}

.luminson__thankyou_section .content p{
    color: #9d9d9d;
    font-size: 20px;
}

.wrapper{
    background-color: #ffffff;
    padding: 40px;
    margin: 0 100px;
    border-radius: 1rem;
}

.luminson__thankyou_section img{
  height: 600px;
  width: 600px;
  object-fit: cover;
}

/* Styles for mobile devices (up to 767px) */
@media (max-width: 767px) {
    .wrapper{
        padding: 20px;
        margin: 0 0;
        border-radius: 1rem;
        margin-top: 20px;
    }
    
    .circleNumber img{
        width: 118px !important;
    }

  .luminson__hero_section{
    height: 100%;
    padding: 55px 0;
}

.luminson__hero_section .h1{
  font-size: 70px;
  line-height: 4rem;
}

.luminson__hero_section_wrapper .h5{
  font-size: 22px;
}

.luminson__hero_section_wrapper .h6{
  font-size: 18px;
}

.luminson__howitworks_section .sectionHeading{
  font-size: 35px;
  margin-bottom: 60px;
}

.circle {
  width: 55px;
  height: 55px;
  font-size: 23px;
}

.luminson__howitworks_wrapper{
  margin-bottom: 40px;
}

.luminson__howitworks_wrapper .h4{
  font-size: 25px;
}

.luminson__widetext_section .h4{
  font-size: 28px;
  padding: 0 0;
}

.luminson__thankyou_section .content .h4{
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
}



.luminson__thankyou_section .content p{
  font-size: 16px;
}

}


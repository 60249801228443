.luminson__expertadvice_section {
    padding: 7.6rem 0;
    background-color: #ffffff;
}

.luminson__expertadvice_image {
    height: 450px;
    position: relative;
}

.luminson__expertadvice_image::before {
    content: '';
    position: absolute;
    height: calc(100% + 100px);
    width: calc(100%);
    top: -50px;
    left: 0px;
    /* background-color: #83dfbd; */
    background-image: linear-gradient(79deg,#8cd0b6,#f7f3a4 85%);
    border-radius: 50%;
}

.luminson__expertadvice_image img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.luminson__expertadvice_caption {
    padding-right: 3rem;
}

.luminson__expertadvice_card {
    text-align: center;
}

.luminson__contact {
    display: block;
    margin-top: 3rem;
}

.luminson__contact .luminson__contact_icon {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.luminson__contact .luminson__contact_icon .luminson__contact_icon_text {
    display: block;
}

.luminson__contact .luminson__contact_icon span.luminson__contact_icon_text:first-child {
    font-size: 1.4rem;
    font-family: 'MullerBold';
    line-height: 1rem;
    margin-top: 0.4rem;
}

.luminson__contact .luminson__contact_icon span.luminson__contact_icon_text:last-child {
    font-family: 'MazzardHRegular';
    font-size: 1.1rem;
}

.luminson__contact .luminson__contact_icon .luminson__contact_icon_image {}

.luminson__contact .luminson__contact_icon .luminson__contact_icon_image svg {
    font-size: 4rem;
}


@media(max-width: 991px) {

    .luminson__expertadvice_section {
        padding: 3.6rem 0 !important;
    }

    .luminson_sectionheading {
        margin-bottom: 4rem;
    }

    .luminson__expertadvice_section p {
        font-size: 0.9rem;
    }

    .luminson__expertadvice_image {
        height: 330px;
    }

    .luminson__expertadvice_image::before {
        content: '';
        position: absolute;
        height: calc(100% + 50px);
        width: calc(100% + 0px);
        top: -25px;
        left: 0px;
        background-color: #83dfbd;
        border-radius: 50%;
    }

    .luminson__contact {
        display: block;
        margin-top: 3rem;
    }

    .luminson__expertadvice_caption {
        padding-right: 2rem;
    }
}


@media(max-width: 767px) {

    .luminson__expertadvice_section {
        padding: 2.6rem 0 1.6em !important;
    }

    .luminson__expertadvice_caption {
        padding-right: 15px;
    }

    .luminson__expertadvice_image * {
        text-align: center;
    }

    .luminson__expertadvice_image {
        margin-bottom: 2rem;
    }

    .luminson__certifiedadvisors_image img {
        position: relative;
        height: auto;
    }

    .luminson__expertadvice_image::before {
        content: '';
        position: absolute;
        height: calc(100% + 25px);
        width: calc(100%);
        top: -12px;
        left: 0px;
        background-color: #83dfbd;
        border-radius: 50%;
    }

    .luminson__contact {
        display: block;
        margin-top: 1rem;
    }
}
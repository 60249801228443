.luminson__optout_section {
    padding: 4.6rem 0;
}


/* OptOutForm.css */

/* Center the form horizontally and vertically */
.luminson__optout_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
}

/* Style the form elements */
.luminson__optout_form {
    max-width: 100%;
    width: 50%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Style form labels and inputs */
.luminson__optout_form .form-group label {
    font-weight: bold;
}

.luminson__optout_form .form-group {
    margin-bottom: 15px;
}

/* Style the radio button options */
.luminson__optout_form input[type="radio"] {
    margin-right: 5px;
}

.luminson__optout_button{
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    color: #3b3a36;
    border: 0;
    border-radius: 2rem;
    font-family: MullerBold;
    font-size: 0.8rem;
    outline: 0;
    padding: 0.7rem 1.1rem;
    text-transform: uppercase;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: MullerBlack
}

p {
  font-family: MazzardHRegular
}



@media(max-width: 991px) {
  .section {
    padding: 2.6rem 0 !important;
  }
}

@media(max-width: 767px) {
  .section {
    padding: 2rem 0 !important;
  }
}








.flip-card-outer {
  height: 340px;
}

.flip-card-outer.focus-trigger:focus {
  outline: 5px solid greenyellow;
  outline-offset: 5px;
}

.flip-card-outer .flip-card-inner {
  transform-style: preserve-3d;
  transition: 0.5s linear 0.1s;
  position: relative;
  width: inherit;
  height: inherit;
  border-radius: 2rem;
}

.flip-card-outer .flip-card-inner.showBack .icon svg{
  transform: rotateY(0deg) scale(4);
}

.flip-card-outer .flip-card-inner.hover-trigger:hover {
  transform: rotateY(180deg);
}

.flip-card-outer .flip-card-inner.showBack {
  transform: rotateY(180deg);
}

.flip-card-outer .flip-card-inner .card {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flip-card-outer .flip-card-inner .card.front {
  transform: rotateY(0);
  color: #fff;
  background-color: #EBE8E3;
}

.flip-card-outer .flip-card-inner .card.back {
  transform: rotateY(180deg);
  background-color: #fff;
  color: #2d2d2d;
}














.chevron-down {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}
.szh-accordion {
  width: 100%;
}
.szh-accordion__item {
    margin-bottom: 3rem;
}



.szh-accordion__item-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 400;
  text-align: left;
  color: #000000;
  background-color: transparent;
  border: none;
  font-family: 'MazzardHRegularSemiBold';
  border-bottom: solid 1px #5B9982;
}

.szh-accordion__item-content {
  transition: height 0.2s ease-in-out;
}
.szh-accordion__item-panel {
  padding: 1rem;
  font-size: 1.5rem;
  font-family: 'MazzardHRegular';
}
.szh-accordion__item--expanded .szh-accordion__item-btn {
  background-color: transparent;
}

.szh-accordion__item img{
    filter: invert(58%) sepia(14%) saturate(992%) hue-rotate(106deg) brightness(92%) contrast(84%);
}
.szh-accordion__item--expanded .chevron-down {
  transform: rotate(180deg);
  filter: invert(58%) sepia(14%) saturate(992%) hue-rotate(106deg) brightness(92%) contrast(84%);
}


.ball-loader {
  width: 250px;
  height: 83.3333333333px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
}

.ball-loader-ball {
  will-change: transform;
  height: 68.1818181818px;
  width: 68.1818181818px;
  border-radius: 50%;
  background-color: lightBlue;
  display: inline-block;
  -webkit-animation: grow 1s ease-in-out infinite alternate;
          animation: grow 1s ease-in-out infinite alternate;
  transform-origin: 50% 50%;
}
.ball-loader-ball.ball1 {
  margin-right: 22.7272727273px;
}
.ball-loader-ball.ball2 {
  margin-right: 22.7272727273px;
  -webkit-animation-delay: -0.33s;
          animation-delay: -0.33s;
}
.ball-loader-ball.ball3 {
  -webkit-animation-delay: -0.66s;
          animation-delay: -0.66s;
}

@-webkit-keyframes grow {
  0% {
    transform: scale(1);
    background-color: #8cd0b6;
  }
  50% {
    background-color: #3b3a36;
  }
  100% {
    transform: scale(0.2);
    background-color: #f7f3a4;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
    background-color: #8cd0b6;
  }
  50% {
    background-color: #3b3a36;
  }
  100% {
    transform: scale(0.2);
    background-color: #f7f3a4;
  }
}


input[type="range"]::-webkit-slider-thumb {
  background: #3b3a36 !important;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  background: #3b3a36 !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  background: #3b3a36 !important;
}

.loadingAnimation{
  padding: 5rem 0;
  float: left;
  width: 100%;
}

.loadingAnimation h2{
  text-align: center;
  font-size: 2.5rem;
}

.loadingAnimation h2 *{
  justify-content: center;
}

.loadingAnimation .wrapper{
  display: block;
  float: left;
  width: 100%;
  position: relative;
  height: 200px;
}


.google-map {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
 }

#map {
  position: relative;
  width: 50%; 
 }

 @media(max-width: 991px) {
  .loadingAnimation h2{
    text-align: center;
    font-size: 2.4rem;
  }
}

@media(max-width: 767px) {
  .loadingAnimation h2{
    text-align: center;
    font-size: 2rem;
  }
}

.calendly-inline-widget {
  height: 100%; /* Adjust the height as needed */
}

.calendly-inline-widget iframe {
  height: 100%; /* Adjust the height as needed */
}
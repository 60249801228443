body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: #73bfb8;
}

@media (min-width: 1200px) {
  .container{
      max-width: 1200px;
  }
}
.luminson__homeefficient_our_approach{
    padding: 4.6rem 0;
}

.luminson__homeefficient__our_approach_left_text{
    margin-bottom: 4rem;
}

.luminson__homeefficient__our_approach_left_text p{
    font-size: 1.5rem;
}

.luminson__homeefficient__our_approach_left_col{
    background-color: #EBE8E3;
}



.luminson__homeefficient__our_approach_left_wrapper_value{
    padding: 1.91rem 0;
}

.luminson__homeefficient__our_approach_left_wrapper_value h6{
    color: #3b3a36;
    margin: 0;
    text-transform: uppercase;
    padding-left: 2.5rem;
    font-family: 'MullerBold';
    font-size: 0.8rem;
}

.luminson__homeefficient__our_approach_left_wrapper_value:first-child{
    padding: 2rem 0;
}

.luminson__homeefficient__our_approach_left_wrapper_value:last-child{
    background-color: #b4b0ac;
    border-top: 4px solid #ffffff;
    padding: 1.98rem 0;
}


.luminson__homeefficient__our_approach_left_wrapper_value:last-child h6{
    color: #3b3a36;
}

.luminson__homeefficient__our_approach_left_wrapper span{
    display: block;
    border-bottom: solid 2px #ffffff;
}

.luminson__homeefficient__our_approach_left_wrapper span:last-child{
    border-bottom: 0;
}


.luminson__homeefficient__our_approach_right_wrapper{
    background-color: #3b3a36;
    border-right: solid 2px #ffffff;
}

.luminson__homeefficient__our_approach_right_col:first-child .luminson__homeefficient__our_approach_right_wrapper{
    position: relative;
    transform:scale(1.1);
    z-index: 99;
    box-shadow: -5px 0 5px -5px #333, 5px 0 5px -5px #333;
    border-right: 0;
    background: #5B9982;
    min-width: 260px;
    left: -45px;
}



.luminson__homeefficient__our_approach_right_col.luminson__homeefficient__our_approach_right_col_active .luminson__homeefficient__our_approach_right_wrapper span{
}

.luminson__homeefficient__our_approach_right_wrapper span{
    display: block;
}

.luminson__homeefficient__our_approach_right_wrapper_value{
    padding: 1.5rem 0;
}

.luminson__homeefficient__our_approach_right_wrapper_value:first-child{
    padding: 2.5rem 0;
}

.luminson__homeefficient__our_approach_right_col.luminson__homeefficient__our_approach_right_col_active .luminson__homeefficient__our_approach_right_wrapper_value:last-child{
    background-color: #84E0BD;
    border-top: 4px solid #ffffff;
}


.luminson__homeefficient__our_approach_right_col.luminson__homeefficient__our_approach_right_col_active .luminson__homeefficient__our_approach_right_wrapper_value:last-child h6{
    color: #3b3a36;
}

.luminson__homeefficient__our_approach_right_col .luminson__homeefficient__our_approach_right_wrapper_value:last-child{
    border-top: 4px solid #ffffff;
    background-color: #b4b0ac;
}

.luminson__homeefficient__our_approach_right_col .luminson__homeefficient__our_approach_right_wrapper_value:last-child h6{
    color: #3b3a36;
}

.luminson__homeefficient__our_approach_right_wrapper_value:first-child h6{
    font-size: 0.8rem;
}


.luminson__homeefficient__our_approach_right_wrapper_value h6{
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    font-family: 'MullerBold';
    font-size: 1.5rem;
    text-align: center;
}

.luminson__homeefficient__our_approach_right_wrapper_value:first-child{
    padding: 2rem 0;
}

.luminson__homeefficient__our_approach_right_wrapper span{
    display: block;
    border-bottom: solid 2px #ffffff;
}

.luminson__homeefficient_hero_section{
    height: calc(100vh - 73.25px);
    position: relative;
}

.luminson__homeefficient_hero_section::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #81e6c1 0%, #81e6c1 28.13%, rgba(0, 82, 202, 0) 72.92%, rgba(0, 82, 202, 0) 100%);
}

.luminson__homeefficient_hero_section img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.luminson__homeefficientfirst_caption{
    position: relative;
    z-index: 99;
}

.luminson__homeefficientfirst_caption h1 {
    font-size: 3.2rem;
    color: #3b3a36;
}

.luminson__homeefficientfirst_caption h4 {
    font-size: 2rem;
    color: #3b3a36;
}














.luminson__homeefficient_faq{
    padding: 4.6rem 0;
}

.luminson_sectionheading {
    margin-bottom: 3rem;
}







@media(max-width: 1199px) {
    .luminson__homeefficient__our_approach_right_col:first-child .luminson__homeefficient__our_approach_right_wrapper{
        min-width: 220px;
        left: -45px;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value h6{
        padding-left: 1.2rem;
    }

    .luminson__homeefficient__our_approach_right_wrapper_value h6{
        color: #ffffff;
        margin: 0;
        text-transform: uppercase;
        font-family: 'MullerBold';
        font-size: 1.2rem;
        text-align: center;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value{
        padding: 1.83rem 0;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value:first-child{
        padding: 2rem 0;
    }

}


@media(max-width: 991px) {
    .luminson__homeefficient__our_approach_right_col:first-child .luminson__homeefficient__our_approach_right_wrapper{
        min-width: 180px;
        left: -45px;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value h6{
        padding-left: 1.2rem;
    }

    .luminson__homeefficient__our_approach_right_wrapper_value h6{
        color: #ffffff;
        margin: 0;
        text-transform: uppercase;
        font-family: 'MullerBold';
        font-size: 1.2rem;
        text-align: center;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value{
        padding: 1.83rem 0;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value:first-child{
        padding: 2rem 0;
    }

    .luminson__homeefficient__our_approach_right_wrapper_value:first-child h6{
        font-size: 0.6rem;
    }

    .luminson__homeefficient__our_approach_left_wrapper_value h6{
        font-size: 0.6rem;
    }

    .luminson__homeefficient__our_approach_right_wrapper_value{
        padding: 1.4rem 0;
    }
}
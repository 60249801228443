.luminson__process_section {
    padding: 4.6rem 0;
    position: relative;
}


.luminson__process_section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../sections/images/process-bg.webp);
    filter: contrast(1) brightness(62%);
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    z-index: 0;
}

.luminson_sectionheading {
    margin-bottom: 6rem;
    position: relative;
    z-index: 9;
}

.luminson_sectionheading * {
    color: #ffffff;
}

.luminson__button {
    text-align: center;
    margin-top: 4rem;
    position: relative;
    z-index: 9;
}

@media(max-width: 991px) {
    .luminson_sectionheading {
        margin-bottom: 4rem;
    }
}

@media(max-width: 767px) {
    .luminson__button {
        margin-top: 2rem;
    }
}
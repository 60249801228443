.button {
    padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(363deg, #3b3a37, #3b3a37 85%);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  outline: none;
  text-decoration: none;
}

.button {
    padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(363deg, #8cd0b6, #f7f3a4 85%);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  outline: none;
}
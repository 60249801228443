.luminson__future_plans label{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.luminson__future_plans label h2{
    font-size: 2.5rem;
    font-family: MullerBold;
    line-height: 3.5rem;
}

.luminson__continue_button{
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%) !important;
    color: #3b3a36;
    text-transform: uppercase;
    border: 0;
    padding: 1rem 4.8rem;
    font-family: 'MazzardHRegularSemiBold';
}

/* .luminson__power_bill input{
    display: none !important;
} */

/* .luminson__power_bill label{
    background-color: transparent !important;
} */

.luminson__option_buttons_wrapper input[type=checkbox]{
    width: 0;
    height: 0;
}

.luminson__option_button{
    border: solid 1px transparent;
    margin: 0 7px;
    font-size: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'MazzardHRegularSemiBold';
    padding: 0.3rem 0.5rem;
}

.luminson__continue_button:hover, .luminson__continue_button:active, .luminson__continue_button:focus {
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%) !important;
    color: #3b3a36 !important;
    box-shadow: 0 0 0 transparent !important;
}

.luminson__option_button:has(input:checked){
    border: solid 1px #97cd9e;
    border-radius: 1rem;
    box-shadow: 2px 5px 21px #97cd9e 

  }

  .luminson__option_buttons_wrapper{
    display: flex;
    padding: 0 9rem;
    margin: 4rem 0;
  }




  
  @media(max-width: 991px) {

    .luminson__future_plans label h2 {
        font-size: 2rem;
        line-height: inherit;
    }

    .luminson__buttons{
    }

    .luminson__continue_button{
        padding: 1rem 3.8rem;
    }

    .luminson__option_buttons_wrapper{
        padding: 0 6rem;
        margin: 0rem 0;
      }
}

@media(max-width: 767px) {

    .luminson__future_plans label h2 {
        font-size: 1.6rem;
        line-height: inherit;
    }

    .luminson__buttons{
    }

    .luminson__continue_button{
        margin-bottom: 1rem;
        padding: 1rem 2.2rem;
    }

    .luminson__option_buttons_wrapper{
        padding: 0 0rem;
        margin: 0rem 0rem;
      }
}
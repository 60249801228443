.luminson__leader_section {
    padding: 4.6rem 0;
}

.luminson__leader_image {
    height: 400px;
    position: relative;
}

.luminson__leader_image img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
}

.luminson__leader_caption {
    padding-left: 3rem;
}

@media(max-width: 991px) {
    .luminson__leader_caption {
        padding-left: 2rem;
    }

    .luminson__leader_section p {
        font-size: 0.9rem;
    }

    .luminson__leader_image {
        height: 330px;
        position: relative;
    }
}


@media(max-width: 767px) {
    .luminson__leader_caption {
        padding-left: 15px;
    }

    .luminson__leader_caption * {
        text-align: center;
    }

    .luminson__leader_image {
        height: auto;
        margin-bottom: 2rem;
    }

    .luminson__leader_image img {
        position: relative;
    }
}
.luminson__terms_section {
    padding: 4.6rem 0;
}

.luminson_sectionheading{
    margin-bottom: 3rem;
}

.luminson__terms_section h5{
    font-family: MazzardHRegular;
    color: #8d8d8d;
    margin-bottom: 2rem;
}


.luminson__terms_section ul{
   display: block; 
}

.luminson__terms_content{
    padding: 0 6rem;
}

.luminson__para{
    margin-bottom: 2.5rem;
    font-family: MazzardHRegular;
    color: #787878;
}

.luminson__list_heading{
    font-family: MazzardHRegular;
    color: #787878;
}

.luminson__para_heading{
    font-family: MazzardHRegular;
    color: #787878;
}

.luminson__list{
    margin-bottom: 2.5rem;
    padding-left: 1rem;
    font-family: MazzardHRegular;
    color: #787878;
}

.luminson__list li{
    padding-bottom: 0.3rem;
}

@media(max-width: 991px) {
    .luminson_sectionheading{
        margin-bottom: 2rem;
    }

    .luminson__terms_content{
        padding: 0 3rem;
    }

    .luminson__terms_section ul, .luminson__para, .luminson__list_heading, .luminson__para_heading, .luminson__list{
        font-size: 0.9rem;
    }
}

@media(max-width: 767px) {
    .luminson__terms_content{
        padding: 0 0rem;
    }
}
.luminson__hero_section {
  background-color: antiquewhite;
  position: relative;
  height: calc(100vh - 79px);
}

.luminson__hero_section_container {
  height: 100%;
}

.luminson__hero_section_row {
  height: 100%;
}

.luminson__hero_section h1 {
  font-size: 56px;
  text-align: center;
  color: #ffffff;
}

.luminson__hero_section h5 {
  font-size: 26px;
  text-align: center;
  color: #ffffff;
}

.luminson__hero_section_background_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.luminson__hero_section_wrapper {
  height: 100%;
}

.video_container {
  position: relative;
  width: 100%;
}

.video_player {
  top: 0;
  left: 0;
  width: 100%;
  height: 375px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}

.video_player .react-player__preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_player .react-player__preview img {
  max-width: 100%;
  max-height: 100%;
}

.video_player img {
  width: 72px;
  /* Adjust the size of the play button */
  height: 72px;
  /* Adjust the size of the play button */
  fill: white;
  /* Adjust the color of the play button */
}

.luminson__we_section {
  padding: 75px 0;
  position: relative;
  background-color: #3c3a36;
}

.luminson__we_row img {
  width: 100%;
  border-radius: 1rem;
}

.luminson__we_section_text .luminson__we_section_text_wrapper {
  height: 100%;
}

.luminson__we_section_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.2;
}

.luminson__we_section_text h1 {
  color: #ffffff !important;
}

.luminson__we_section_text p {
  color: #ffffff;
}

.luminson__we_section_row {
  padding: 75px 0;
}

.luminson__cards_section {
  padding: 75px 0;
  position: relative;
}



.luminson__cards_section_card {
  text-align: center;
  border-radius: 1rem;
}

.luminson__cards_section_card img {
  width: 150px;
  margin-bottom: 20px;
}

.luminson__cards_section_card_title {
  font-weight: bold;
}

.luminson__stats_section {
  padding: 75px 0;
  background-color: #3b3a36;
}

.luminson__stats_section h4 {
  color: #ffffff;
  font-size: 23px;
  margin-bottom: 20px;
}

.luminson__stats_section h1 {
  color: #efba12;
  font-size: 57px;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.luminson__lead_distribution_section {
  padding: 70px 0;
  position: relative;
}

.luminson__lead_distribution_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.luminson__lead_distribution_image_column {
  background-color: #3b3a36c9;
  padding: 75px 20px;
  height: 100%;
  border-radius: 1rem;
}

.luminson__lead_distribution_image_column .h1 {
  font-weight: 300;
  font-size: 40px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 30px;
}

.luminson__lead_distribution_image_column .h1 span {
  font-weight: 700;
  color: #efba12;
}

.luminson__lead_distribution_image_column .h4 {
  font-weight: 300;
  font-size: 22px;
  text-align: left;
  color: #ffffff;

}

.luminson__lead_distribution_image_column ul {
  list-style: none;
  padding-left: 0;
}

.luminson__lead_distribution_image_column ul li {
  margin-bottom: 20px;
}

.luminson__lead_distribution_image_column .h5 {
  font-weight: 300;
  font-size: 22px;
  text-align: left;
  color: #ffffff;
}

.bulletIcon {
  margin-right: 0.5rem;
}

.luminson__lead_mainline_section {
  padding: 55px 0;
  background-color: #3b3a36;
}

.luminson__lead_mainline_section .h1 {
  color: #efba12;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
}

.luminson__lead_companies_section {
  padding: 70px 0;
}

.luminson__lead_companies_section .h5 {
  font-weight: 800;
  font-size: 34px;
  text-align: center;
  color: #3b3a36;
  width: 100%;
}

.luminson__lead_companies_section img {
  width: 100%;
  filter: grayscale(100%);
}

.luminson__schedule_call_section{
  padding: 70px 0;
  background-color: #3b3a36;
}

.luminson__schedule_call_section .h5 {
  font-weight: 800;
  font-size: 34px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin-bottom: 50px;
}

/* Styles for mobile devices (up to 767px) */
@media (max-width: 767px) {
  /* Add your mobile styles here */
  .luminson__hero_section {
  padding: 70px 0;
  height: auto;
}
  .luminson__hero_section h1 {
    font-size: 36px
  }

  .luminson__hero_section h5 {
    font-size: 19px;
    margin-bottom: 30px;
  }

  .video_player {
    height: 275px;
  }

  .luminson__we_section_text h1 {
    font-size: 29px !important;
  }

  .luminson__we_section_row {
    padding: 5px 0;
  }

  .luminson__we_section_text_wrapper {
    margin: 0px 0 50px
  }

  .luminson__stats_section h4 {
    font-size: 22px;
  }
  
  .luminson__stats_section h1 {
    font-size: 37px;
  }

  .luminson__stats_section .column{
    margin-bottom: 35px;
  }

  .luminson__lead_distribution_image_column {
    padding: 25px 5px;
  }


  .luminson__lead_distribution_image_column .h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  
  .luminson__lead_distribution_image_column .h4 {
    font-size: 18px;
  }
  
  .luminson__lead_distribution_image_column .h5 {
    font-size: 18px;
  }

  .luminson__lead_distribution_section .column{
    margin-bottom: 35px;
  }

  .luminson__lead_distribution_section .column:last-child{
    margin-bottom: 0;
  }

  .luminson__lead_mainline_section .h1 {
    font-size: 29px;

  }

  .luminson__lead_companies_section .h5 {
    font-size: 22px;
  }

  .luminson__schedule_call_section .h5 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

/* Styles for tablet devices (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  /* Add your tablet styles here */
  
}

.luminson__heading{
    background-color: #ebe8e3;
    float: left;
    width: 100%;
    padding: 2rem;
    border-radius: 1rem;
}

.luminson__heading h3{
    margin-bottom: 0;
    text-align: center;
    font-family: MullerBold;
}

.luminson__text{
    padding: 1rem;
    float: left;
    width: 100%;
}

.luminson__text h3{
    text-align: center;
    font-family: MullerBold;
}

.luminson__cols{
    margin-top: 2rem;
}

.luminson__cols h5{
    font-family: MullerBold;
}

.luminson__data{
    background-color: #ebe8e3;
    float: left;
    width: 100%;
    padding: 1.5rem;
    border-radius: 1rem;
}

.luminson__data h5{
    
}

.luminson__data h5 span{
    color: #78c0b2;
}


.luminson__button{
    float: left;
    width: 100%;
    margin-top: 2rem;
}

.luminson__button a{
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%) !important;
    color: #3b3a36;
    text-transform: uppercase;
    font-size: 1.3rem;
    padding: 0.8rem 0.5rem;
    display: block;
    border-radius: 4rem;
    text-align: center;
    text-decoration: none;
    font-family: MullerBold;
    -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}



.luminson__cols_left div{
    width: 100%;
    height: 345px;
    position: relative;
    top: -25px;
}

.luminson__cols_left div::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: #ffffff;
}

.luminson__cols_left div::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: #ffffff;
}

.luminson__cols_left div img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
}

.luminson__cols_left h5{
    margin-top: 0em;
    text-align: center;
    font-family: 'MazzardHRegular';
    font-size: 0.9rem;
}

.luminson__modal{
    border-radius: 2rem;
}

.luminson__modal .luminson__modal_body{
    background-color: #ebe8e3 !important;
    border-radius: 2rem;
    padding: 2rem 3rem;
}

.luminson__modal .luminson__modal_body .luminson__modal_title{
    text-align: left;
    margin-bottom: 3rem;
}

.luminson__modal .luminson__modal_body .luminson__modal_title h1{
    font-family: MullerBold;
}

.luminson__modal .luminson__modal_body .luminson__modal_title h5{
    font-family: MullerBold;
}

.luminson__modal .luminson__modal_body h6{
    font-family: MullerBold;
}

.luminson__modal{
    border-radius: 2rem;
}

.luminson__modal .luminson__modal_body .luminson__field{
    margin-bottom: 4rem;
}

.luminson__modal .luminson__modal_body .luminson__field input{
    padding: 1.8rem 0.5rem;
}

.luminson__continue_button {
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%) !important;
    color: #3b3a36;
    text-transform: uppercase;
    border: 0;
    padding: 1rem 4.8rem;
    font-family: 'MazzardHRegularSemiBold';
    align-items: center;
}

.luminson__continue_button:hover, .luminson__continue_button:active, .luminson__continue_button:focus, .luminson__continue_button:disabled {
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%) !important;
    color: #3b3a36 !important;
    box-shadow: 0 0 0 transparent !important;
}

.luminson__thank_you{
    font-family: 'MazzardHRegular';
    margin-top: 3rem;
}


















@keyframes glowing {
    0% {
        background-color: #8cd0b6;
        box-shadow: 0 0 3px #8cd0b6;
    }
    50% {
        background-color: #f7f3a4;
        box-shadow: 0 0 20px #f7f3a4, 0 0 30px #f7f3a4;
    }
    100% {
        background-color: #8cd0b6;
        box-shadow: 0 0 3px #8cd0b6;
    }
}


/* 
@-webkit-keyframes glowing {
    0% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
    50% { background-color: #2bcc8e; -webkit-box-shadow: 0 0 40px #2bcc8e; }
    100% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
  }
  
  @-moz-keyframes glowing {
    0% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
    50% { background-color: #2bcc8e; -webkit-box-shadow: 0 0 40px #2bcc8e; }
    100% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
  }
  
  @-o-keyframes glowing {
    0% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
    50% { background-color: #2bcc8e; -webkit-box-shadow: 0 0 40px #2bcc8e; }
    100% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
  }
  
  @keyframes glowing {
    0% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
    50% { background-color: #2bcc8e; -webkit-box-shadow: 0 0 40px #2bcc8e; }
    100% { background-color: #84e0bd; -webkit-box-shadow: 0 0 3px #84e0bd; }
  } */
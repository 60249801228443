.luminson__section_heading {
    margin-bottom: 1.6rem;
}

.luminson__heading {
    font-size: 2.5rem;
    color: #000000;
}

.luminson__heading span {
    color: #73bfb8;
}


.luminson__sub {
    font-size: 1.25rem;
    color: #3b3a36;
    font-family: 'MullerBold';
    margin-bottom: 0;
}


@media(max-width: 991px) {
    .luminson__heading {
        font-size: 2rem;
    }

    .luminson__sub {
        font-size: 0.9rem;
    }
}

@media(max-width: 767px) {
    .luminson__heading {
        font-size: 1.4rem;
    }

    .luminson__sub {
        font-size: 0.65rem;
    }

    .luminson__section_heading {
        margin-bottom: 1rem;
    }
}
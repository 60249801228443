.luminson__card_wrapper {
    padding: 0 1rem;
    height: 100%;
}

.luminson__card_block {}

.luminson__card_block .luminson__single_card.dark {
    border: 0;
    background-color: transparent;
    height: 100%;
    /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); */
    border-radius: 1rem;
}

.luminson__card_block:nth-child(n+4) {
    margin-top: 4rem;
}

.luminson__card_block h3 {
    /* color: #EB5E55; */

    background: -webkit-linear-gradient(#f7f3a4, #a9d9b1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
        font-size: 1.3rem;
    font-family: MullerBold;
    text-align: center;
}

.luminson__card_block .luminson__single_card.dark ul {
    padding-left: 1rem;
}

.luminson__card_block .luminson__single_card.dark ul li {
    padding-bottom: 0.65rem;
}

.luminson__card_block .luminson__single_card.dark ul li:last-child {
    padding-bottom: 0;
}

.luminson__card_block .luminson__single_card.dark p,
.luminson__card_block .luminson__single_card.dark ul {
    color: #000000;
    font-family: 'MazzardHRegular';
}

.luminson__card_block .luminson__single_card.dark .luminson__icon {
    height: 100%;
    width: 100%;
    /* margin-bottom: 2rem; */
    position: relative;
}

.luminson__card_block .luminson__single_card.dark .luminson__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    /* margin-top: 48px; */
}

.luminson__card_block .luminson__single_card.dark .luminson__icon.extended img {
    position: relative;
    top: 20px;
}

.luminson__card_block .luminson__single_card.dark .luminson__icon.full img {
    position: absolute;
    left: -12px;
    top: -12px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
}

.luminson__card_block .luminson__single_card.dark .luminson__icon img.person {}

.luminson__card_block .luminson__single_card.dark .luminson__plus_icon {
    position: absolute;
    top: 50%;
    right: -0.5rem;
    transform: translateX(100%) translateY(-50%);
    display: none;
}

.luminson__card_block .luminson__single_card.dark .luminson__plus_icon svg {
    filter: invert(81%) sepia(5%) saturate(1661%) hue-rotate(77deg) brightness(92%) contrast(95%);;
    font-size: 3rem;
}

.luminson__card_block:nth-child(3) .luminson__single_card.dark .luminson__plus_icon {
    display: none;
}

.luminson__card_block:nth-child(6) .luminson__single_card.dark .luminson__plus_icon {
    display: none;
}

.luminson__card_block .luminson__title {
    margin-bottom: 1rem;
}

.luminson__card_block .luminson__single_card.light {
    border: 0;
    background-color: #ebe7e3;
    height: 100%;
    border-radius: 1rem;
}

.luminson__card_block:has(.light):nth-child(n+3) {
    margin-top: 4rem;
}

.luminson__card_block .luminson__single_card.light h3 {
    color: #1B1B19;
    font-family: MullerBold;
    font-size: 1.3rem;
    text-align: center;
    background: transparent;
    -webkit-text-fill-color: #1b1b19;
}

.luminson__card_block .luminson__single_card.light ul {
    padding-left: 1rem;
}

.luminson__card_block .luminson__single_card.light ul li {
    padding-bottom: 0.65rem;
}

.luminson__card_block .luminson__single_card.light ul li:last-child {
    padding-bottom: 0;
}

.luminson__card_block .luminson__single_card.light p,
.luminson__card_block .luminson__single_card.light ul {
    color: #494949;
    font-family: 'MazzardHRegular';
}

.luminson__card_block .luminson__single_card.light .luminson__icon {
    height: 100%;
    width: 100%;
    /* margin-bottom: 2rem; */
    position: relative;
}

.luminson__card_block .luminson__single_card.light .luminson__icon {
    height: 160px;
    margin-bottom: 2rem;
}

.luminson__card_block .luminson__single_card.light .luminson__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.luminson__card_block .luminson__single_card.light .luminson__icon.extended img {
    display: none;
}

.luminson__card_block .luminson__single_card.light .luminson__icon .luminson__plus_icon {
    display: none;
}



@media(max-width: 1199px) {
    .luminson__card_block .luminson__single_card.dark .luminson__icon.extended img {
        position: relative;
        top: 55px;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon.full img {
        position: absolute;
        left: -12px;
        top: -12px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
    }
}


@media(max-width: 991px) {
    .luminson__card_wrapper {
        padding: 0 0.5rem;
    }

    .luminson__card_block h3 {
        font-size: 1rem !important;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon {
        height: 100% !important;
        width: 100%;
    }

    .luminson__card_block .luminson__single_card ul,
    .luminson__card_block .luminson__single_card p {
        font-size: 0.85rem !important;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon img {
        object-position: center;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon .luminson__plus_icon {
        right: -1.65rem !important;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon .luminson__plus_icon svg {
        font-size: 2rem !important;
    }

    .luminson__card_block:nth-child(n+3) {
        margin-top: 2.5rem;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__plus_icon {
        display: none;
    }

    .luminson__card_block:has(.light):nth-child(n+3) {
        margin-top: 2.7rem;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon.extended img {
        position: relative;
        top: 55px;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon.full img {
        position: absolute;
        left: -15px;
        top: -20px;
        width: calc(100% + 40px);
        height: calc(100% + 40px);
    }
}


@media(max-width: 767px) {
    .luminson__card_wrapper {
        padding: 0 0.5rem;
        text-align: center;
        overflow: hidden;
    }

    .luminson__card_block h3 {
        font-size: 1rem !important;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon {
        height: 100% !important;
        width: 100%;
    }

    .luminson__card_block .luminson__single_card ul,
    .luminson__card_block .luminson__single_card p {
        font-size: 0.85rem !important;
    }

    .luminson__card_block .luminson__single_card ul {
        display: table;
        margin: 0 auto;
        text-align: left;
    }

    .luminson__card_block .luminson__single_card.light .luminson__icon img {
        object-position: center;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon .luminson__plus_icon {
        display: none !important;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon .luminson__plus_icon svg {
        font-size: 2rem !important;
    }

    .luminson__card_block {
        margin-top: 0 !important;
        margin-bottom: 2.5rem;
    }

    .luminson__card_block:last-child {
        margin-top: 0 !important;
        margin-bottom: 0rem;
    }

    .luminson__card_block:nth-child(3) .luminson__single_card.dark .luminson__icon .luminson__plus_icon {
        display: block;
    }

    .luminson__card_block:nth-child(6) .luminson__single_card.dark .luminson__icon .luminson__plus_icon {
        display: block;
    }

    .luminson__card_block:nth-child(even) .luminson__single_card.dark .luminson__icon .luminson__plus_icon {
        display: none;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon.extended img {
        position: relative;
        top: 55px;
    }

    .luminson__card_block .luminson__single_card.dark .luminson__icon.full img {
        position: absolute;
        left: -13px;
        top: -13px;
        width: calc(100% + 25px);
        height: calc(100% + 25px);
    }
}
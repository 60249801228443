.luminson__card{
    padding: 3rem 2rem;
    margin: 0 1rem;
    border-radius:1rem;
}

.luminson__card .luminson__card_review{
    min-height: 100px;
    max-height: 100px;
}

.luminson__card .luminson__card_name{
    margin-top: 1rem;
}

.luminson__card .luminson__card_review p{
    font-size: 1.1rem;
    font-family: MazzardHRegular;
    color: #6d6d6d;
}

.luminson__card .luminson__card_name h5{
    color: #6bc2a1;
    font-family: MullerBold;
    font-size: 1rem;
}

.luminson__card .luminson__card_name p{
    color: rgba(148, 175, 173, 0.753);
    font-family: MazzardHRegular;
    font-size: 0.85rem;
    margin-bottom: 0;
}

@media(max-width: 991px) {
    .luminson__card{
        margin: 0 1rem;
    }

    .luminson__card .luminson__card_review p{
        font-size: 0.9rem;
    }

    .luminson__card .luminson__card_review{
        min-height: 70px;
        max-height: 70px;
    }

    .luminson__card .luminson__card_name h5{
        font-size: 1.1rem;
    }

    .luminson__card .luminson__card_name p{
        font-size: 0.75rem;
    }
}

@media(max-width: 767px) {
    .luminson_review_slider {
        padding: 2rem 0;
    }
}
.luminson__address input[name=address] {
    border: solid 3px black;
    padding: 1.5rem 1rem;
}

.luminson__address label {
    text-align: center;
}

.luminson__address label h2 {
    font-size: 2.5rem;
    font-family: MullerBold;
    line-height: 3.5rem;
}

.luminson__continue_button {
    /* background-color: #86e1bd; */
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    color: #3b3a36;
    text-transform: uppercase;
    border: 0;
    padding: 1rem 4.8rem;
    font-family: 'MazzardHRegularSemiBold';
}

.luminson__continue_button:hover, .luminson__continue_button:active, .luminson__continue_button:focus {
    background-image: linear-gradient(363deg,#8cd0b6,#f7f3a4 85%);
    color: #3b3a36 !important;
    box-shadow: 0 0 0 transparent !important;
}


@media(max-width: 991px) {
    .luminson__address label h2 {
        font-size: 2rem;
        line-height: inherit;
    }
}

@media(max-width: 767px) {
    .luminson__address label h2 {
        font-size: 1.6rem;
        line-height: inherit;
    }
}